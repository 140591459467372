import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactSuccessPage = (props) => (
      <Layout>
        <SEO description="BuilderCover is the services concierge to building developers and owners. According to your project, we put our clients in touch with the highest performing vendors across many service industries."
        title="Thanks for the Note" 
        keywords={[`construction`, `building services`, `engineering`, `construction insurance`]} />
        <section className="hero is-small is-primary">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-1 is-hidden-touch">
                </div>
                <div className="column is-8">
                  <h2 className="subtitle is-size-4 is-uppercase has-text-info">
                    Contact Us
                  </h2>
                  <h1 className="title has-text-white is-size-1-desktop is-size-2-touch has-text-weight-bold">
                    Have a question? <br/>Just ask!
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section container">
          <h1 className="is-size-2">
            Thanks for sending a note! We will be in contact shortly!
          </h1>
          <div ClassName="columns">
            <div className="column is-4 is-offset-4">
              <Img fluid={props.data.cogsImage.childImageSharp.fluid}/>
            </div>
          </div>
        </section> 
      </Layout>
    )

export default ContactSuccessPage

export const pageQuery = graphql `
  query {
    cogsImage: file(relativePath: { eq: "cogs.png"}) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;